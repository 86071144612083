import {
    DestroyRef,
    Injectable,
    Injector,
    Signal,
    WritableSignal,
    computed,
    effect,
    signal,
    untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrganisationTreeNodeType } from '@em4cloud/organisation-tree';
import { TranslocoService } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MetadataDescriptor, MetadataDescriptorFieldsInner } from 'app/api';
import { SaveButtonService } from 'app/core/save-button/save-button.service';
import { cloneDeep, isEqualWith, merge } from 'lodash';

@Injectable({ providedIn: 'root' })
export class OrgSysAreaFormService<T> {
    itemTypes: Signal<any>;

    selectedItem: WritableSignal<T> = signal(null);
    isDialog: WritableSignal<boolean> = signal(false);

    originalValue = computed(
        () => {
            return { ...this.selectedItem() };
        },
        {
            equal: (a, b) => a['id'] === b['id'],
        }
    );

    private _changedFormValue: WritableSignal<Partial<T>> = signal(null);
    public get changedFormValue(): Signal<Partial<T>> {
        return this._changedFormValue.asReadonly();
    }

    public set changedFormValue(value: Partial<T>) {
        this._changedFormValue.set(value);
    }

    private _validForm: WritableSignal<boolean> = signal(false);
    public get validForm(): Signal<boolean> {
        return this._validForm.asReadonly();
    }

    public set validForm(value: boolean) {
        this._validForm.set(value);
    }

    private _hasChanges: Signal<boolean>;
    public get hasChanges(): Signal<boolean> {
        return this._hasChanges;
    }

    // if the form is in edit mode
    private _editableForm = signal<boolean>(false);
    get editableForm(): Signal<boolean> {
        return this._editableForm.asReadonly();
    }
    set editableForm(value: boolean) {
        this._editableForm.set(value);
    }

    // form fields definition
    formFields = computed<FormlyFieldConfig[]>(() => {
        const isDialog = this.isDialog();
        this.selectedItem();
        return this.constructFormFields(isDialog);
    });

    formType: WritableSignal<OrganisationTreeNodeType | 'root'> = signal(null);

    private _service: any; // OrgSysAreaService

    public set service(service: any) {
        this._service = service;
        if (this._service) {
            this.initComputedProperties();
        }
    }

    _translocoContent = signal(null);

    constructor(
        private inject: Injector,
        private _saveButtonService: SaveButtonService,
        private _destroyRef: DestroyRef,
        private _translocoService: TranslocoService
    ) {
        this._translocoService
            .selectTranslation('bpOrganisation')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(content => {
                this._translocoContent.set(content);
            });
        // if the form has changes
        this._hasChanges = computed(() => {
            if (!this.editableForm()) return false;
            if (
                this._service.selectedItem() &&
                this._service.selectedItem().id === this.originalValue()['id']
            ) {
                let hasChanges = !isEqualWith(this.originalValue(), {
                    ...this._service.selectedItem(),
                    ...this.changedFormValue(),
                });
                return hasChanges;
            }
            return false;
        });

        // show warn icon on save button on changes
        effect(
            () => {
                this._saveButtonService.hasUnsavedChanges = this.hasChanges();
            },
            { allowSignalWrites: true }
        );

        this._saveButtonService.continueWithoutSaving
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe({
                next: value => {
                    if (value === true) {
                        this.resetOriginalData();
                    }
                },
            });
    }

    private initComputedProperties(): void {
        this.itemTypes = computed(this._service.typeDefs);
        effect(
            () => {
                const selectedItem = this._service.selectedItem();
                if (selectedItem) {
                    this.selectedItem.set(selectedItem);
                }
            },
            {
                injector: this.inject,
                allowSignalWrites: true,
            }
        );
    }

    resetOriginalData() {
        // let currentData = this._apiService._data();
        // const itemIndex = currentData.findIndex(item => item.id === this.originalFormValue()?.id);
        // if (!itemIndex) return;
        // currentData[itemIndex] = this.originalFormValue();
        // this._apiService._data.update(() => [...currentData]);
    }

    constructFormFields(isDialog: boolean): FormlyFieldConfig[] {
        let selectedItem = this.selectedItem();
        let rootNode;
        let isRootNode;
        let type = 'root';

        if (selectedItem) {
            if (selectedItem['version'] && selectedItem['versionId']) {
                isRootNode = true;
            } else {
                rootNode = this._service.findRootParentNode(selectedItem['id']);
                isRootNode = !rootNode || rootNode.id === selectedItem['id'];

                if (selectedItem) type = selectedItem['type'] || 'root';
            }
        } else {
            isRootNode = true;
        }

        if (this.formType()) type = this.formType().toLowerCase();

        let fields: FormlyFieldConfig[] = [
            {
                type: 'object',
                props: {
                    label: this._translocoContent()['form.basicDetails'],
                    labelClass: 'h6',
                },
                fieldGroup: [
                    {
                        type: 'string',
                        key: 'title',
                        name: 'title',
                        props: {
                            label: this._translocoContent()['form.title'],
                            required: true,
                        },
                        validators: {
                            atLeastTwoTypesOfLetters: {
                                expression: control => new Set(control.value).size > 1,
                                message: this._translocoContent()['form.titleValidation'],
                            },
                        },
                    },
                    {
                        type: 'string',
                        key: 'code',
                        name: 'code',
                        props: {
                            label: this._translocoContent()['form.code'],
                            required: true,
                        },
                        // validators: {
                        //     atLeastTwoTypesOfLetters: {
                        //         expression: control => new Set(control.value).size > 1,
                        //         message: this._translocoContent()['form.titleValidation'],
                        //     },
                        // },
                    },
                ],
            },
        ];

        // root case
        if (isRootNode && !isDialog) {
            fields[0].fieldGroup.push({
                type: 'string',
                key: 'version',
                name: 'version',
                props: {
                    label: this._translocoContent()['form.version'],
                    required: true,
                },
            });
            fields[0].fieldGroup.push({
                type: 'string',
                key: 'status',
                name: 'status',
                props: {
                    label: this._translocoContent()['form.status'],
                    required: true,
                },
            });
        }

        let defType = this.itemTypes()?.find(
            itemType => itemType.name.toLowerCase() === type.toLowerCase()
        );

        if (defType) {
            fields.push({
                type: 'object',
                key: 'infoTypes',
                name: 'infoTypes',
                props: {
                    label: this._translocoContent()['form.infoTypes'],
                    labelClass: 'h6',
                },
                fieldGroup: defType.infoTypes.map(infoType =>
                    this.constructFieldsFromInfotype(infoType)
                ),
            });
        }

        return fields;
    }

    constructFieldsFromInfotype(infoType: MetadataDescriptor): Partial<FormlyFieldConfig> {
        let field = {
            type: 'object',
            key: infoType.id,
            name: infoType.id,
            props: {
                label: infoType.id,
            },
            fieldGroup: infoType.fields.map(info =>
                this.constructFieldFromMetadataDescriptor(info)
            ),
        };
        return field;
    }

    constructFieldFromMetadataDescriptor(
        descriptor: MetadataDescriptorFieldsInner
    ): Partial<FormlyFieldConfig> {
        let field = {
            type: descriptor.fieldType,
            key: descriptor.propertyName,
            name: descriptor.propertyName,
            props: {
                label: descriptor.label,
            },
        };
        return field;
    }

    save() {
        let state = cloneDeep(untracked(this._service.state)) as T[];

        state = state.filter(item => item['status'] === 'DEVELOPMENT');

        if (!state || !state.length) {
            return;
        }

        const newNodeValue: Partial<T> = merge(this.originalValue(), this._changedFormValue());
        newNodeValue['id'] = newNodeValue['id'].split('_')[1];

        const rootNode = this.modifyNodeAndReturnParent(state, newNodeValue);

        if (!rootNode) return;

        this._saveButtonService.setSaveFinished();
        this._service.updateItem(rootNode);
    }

    modifyNodeAndReturnParent(nodes: T[], modification: Partial<T>): T | null {
        let rootNode: T = null;

        function findAndModify(children: T[], currentLevel = 0): boolean {
            for (const child of children) {
                if (currentLevel === 0) rootNode = child;
                if (child['id'] === modification['id']) {
                    Object.assign(child, modification);
                    return true;
                }
                if (child['children'].length) {
                    const found = findAndModify(child['children'], currentLevel + 1);
                    if (found) return true;
                }
            }
            return false;
        }

        const found = findAndModify(nodes);
        if (found) return rootNode;
        return null;
    }
}
