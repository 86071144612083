import { Injectable, Signal, computed, effect } from '@angular/core';
import { SimpleOrganisation } from 'app/api';
import { UserService } from 'app/core/user/user.service';
import { OrgService } from 'app/modules/org-sys-area/services/org.service';
import { cloneDeep } from 'lodash';
import { GloballySelectedOrgService } from './globally-selected-org.service';

export const ORGANISATION_KEY_NAME = 'organisation';
export const ALL = '';

@Injectable({
    providedIn: 'root',
})
export class OrganisationFilterService {
    organisations: Signal<SimpleOrganisation[]> = computed(() => {
        let organisations = [];

        if (this.organisationComponentService.simpleOrganisations()) {
            organisations = cloneDeep(this.organisationComponentService.simpleOrganisations());
        }

        return organisations;
    });

    constructor(
        private organisationComponentService: OrgService,
        private globallySelectedOrgService: GloballySelectedOrgService,
        private userService: UserService
    ) {
        const organisation = localStorage.getItem(ORGANISATION_KEY_NAME);
        this.selectedOrganisation = organisation;

        this.userService.user$.subscribe(user => {
            if (user && user.organisation && user.userType === 'SUBSCRIPTION') {
                this.selectedOrganisation = user.organisation;
            }
        });

        // synchronize local storage
        effect(() => {
            localStorage.setItem(
                ORGANISATION_KEY_NAME,
                this.globallySelectedOrgService.selectedOrganisation()
            );
        });
    }

    public get selectedOrganisation(): Signal<string> {
        return this.globallySelectedOrgService.selectedOrganisation.asReadonly();
    }

    public set selectedOrganisation(organisation: string) {
        this.globallySelectedOrgService.selectedOrganisation.set(organisation);
        this.userService.currentOrgId.set(organisation);
    }
}
